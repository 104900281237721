import axios from 'axios'
import { useState, useCallback } from 'react'
import { browserName, osName, osVersion, isIOS, isAndroid, isMobileOnly, isTablet, isBrowser, browserVersion } from 'react-device-detect';

export function getDeviceType() {
  if (isIOS || isAndroid) {
      return 'MobApp';
  } else if (isMobileOnly) {
      return 'MobWeb';
  } else if (isTablet) {
      return 'Tablet';
  } else if (isBrowser) {
      return 'Pc';
  } else {
      return 'Other';
  }
}

const useHttp = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const request = useCallback(async (url, method = 'GET', data = null) => {
    setLoading(true)

    try {
      let response
      await axios(url, { method, data, withCredentials: true }).then(res => {
        response = res
      })

      setLoading(false)
      return response
    } catch (e) {
      await fetch('https://logs.juun.ru/logs/logs', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({
          project: 1,
          log_level: "Error",
          device_type: getDeviceType(),
          os: `${osName} ${osVersion}`,
          browser: `${browserName} ${browserVersion}`,
          message: JSON.stringify({
            request: e.request, 
            response: e.response, 
            error: {
              message: e.message, 
              config: e.config
            }
          })
        })
      });
      setLoading(false)
      setError(e.message)
      throw e
    }
  }, [])

  const clearError = useCallback(() => {
    setError(null)
  }, [])

  return { loading, request, error, clearError, setLoading }
}

export { useHttp }
