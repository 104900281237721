import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material'

import { SendForm } from '../Form'

const PaymentDialog = ({ open, setOpen, payData, oneMethod, setLoading }) => {
  const [t] = useTranslation()

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Dialog open={open} aria-labelledby="responsive-dialog-title">
        <DialogTitle sx={{ fontSize: '24px',padding: '40px 60px 20px 60px' }} id="responsive-dialog-title"  className='dialogBlock__title'>
          {t('payment.confirmAsk')}
        </DialogTitle>
        <DialogActions sx={{gap: '1em',padding: '0 60px 40px 60px'}} className='dialogBlock__actions'>
          <Button className='dialogBlock__btnDecline' sx={{ color: '#3F3F3F!important', borderColor: '#969696!important', fontFamily: 'inherit!important', fontWeight: '500!important'}} variant='outlined' onClick={handleClose}>{t('payment.disagree')}</Button>
          <SendForm
            payData={payData}
            oneMethod={oneMethod}
            setLoading={setLoading}
            setOpen={setOpen}
          />
        </DialogActions>
      </Dialog>
    </div>
  )
}

export { PaymentDialog }
