import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import PropTypes from 'prop-types'
import { Box, Button, Tabs, Tab, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'

import { toRegExp } from '../../../utils/regexp'
import { usePaymentService } from '../../../services/paymentService'

import { PaymentDialog } from '../../Dialog'
import { Loading } from '../../Loading'
import { ErrorMessage } from '../../ErrorMessage'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 4, pl: 4 }}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  }
}

const Payment = () => {
  const [paymentMethods, setPaymentMethods] = useState([])
  const [paymentInfo, setPaymentInfo] = useState({})
  const [open, setOpen] = useState(false)
  const [payData, setPayData] = useState({})
  const [fields, setFields] = useState({})
  const [validation, setValidation] = useState(false)
  const [oneMethod, setOneMethod] = useState(false)
  const [value, setValue] = useState(0)

  const [t, i18n] = useTranslation()

  const navigate = useNavigate()

  const {
    getPaymentInfo,
    getPaymentMethods,
    putPaymentInfo,
    loading,
    error,
    clearError,
    setLoading
  } = usePaymentService()

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const sendPaymentData = (id, payment, fields) => {
    putPaymentInfo(id, payment, fields).then(res => {
      setPayData(res)
      setOpen(true)
    })
  }

  const fieldsValidation = (field, value) => {
    setValidation(toRegExp(field.regex).test(value))
  }

  useEffect(() => {
    const interval = setTimeout(() => clearError(), 5000)

    return () => {
      clearTimeout(interval)
    }
  }, [error])

  useEffect(() => {
    getPaymentMethods()
      .then(res => setPaymentMethods(res))
      .catch(e => e && navigate('not-found'))

    getPaymentInfo()
      .then(res => {
        setPaymentInfo(res)
        i18n.changeLanguage(res.lang)
      })
      .catch(e => e && navigate('not-found'))
  }, [])

  useEffect(() => {
    if (paymentMethods.length === 1) {
      paymentMethods.map(payment => {
        if (payment.fields.length === 0) {
          setOneMethod(true)
          sendPaymentData(payment.id, payment, payment.fields)
        }
      })
    }
  }, [paymentMethods])

  const getImgPayment = name => {
    switch (name) {
      // СБП с кэшбеком
      case 'СБП':
        return (
          <div>
            <div className="paymentPage__cashback">Кэшбэк 3%</div>
            <img
              src="./paymentSbp.png"
              style={{ maxWidth: '132px' }}
              className="paymentPage__img"
            />
          </div>
        )
      // СБП с без кэшбека
      // case 'СБП':
      //   return <img src='./paymentSbp.png' style={{maxWidth: '132px'}} className='paymentPage__img' />
      case 'Банковские Карты':
        return (
          <img src="./paymentVisa.png" style={{ maxWidth: '228px' }} className="paymentPage__img" />
        )
      default:
        return name
    }
  }

  const getTemplateOldPayment = () => {
    return (
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: 'background.paper',
          display: 'flex',
          visibility: loading ? 'hidden' : 'visible',
          height: 450,
          justifyContent: 'center',
          p: 1
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          scrollButtons={true}
          allowScrollButtonsMobile
          onChange={handleChange}
          aria-label="Payment Methods"
          sx={{ borderRight: 1, borderColor: 'divider', width: '280px', pt: 1 }}
        >
          {paymentMethods.map((payment, i) => (
            <Tab
              style={{ fontSize: '14px', minHeight: '32px', padding: 0 }}
              key={payment.id}
              label={payment.name}
              {...a11yProps(i)}
              onClick={() => clearError}
            />
          ))}
        </Tabs>
        {paymentMethods.map((payment, i) => (
          <TabPanel key={payment.id} value={value} index={i} style={{ width: '400px' }}>
            {payment.fields.map((field, i) => (
              <TextField
                key={i}
                onChange={e => {
                  fieldsValidation(field, e.target.value)
                  setFields({
                    [field.key]: e.target.value
                  })
                }}
                label={field.name}
                margin="normal"
                required
                id="filled"
                error={!validation}
                helperText={!validation ? field.message : null}
                fullWidth
                sx={{ display: 'block' }}
              />
            ))}
            {Object.keys(paymentInfo).map(
              (key, i) =>
                key !== 'lang' && (
                  <TextField
                    key={i}
                    disabled
                    id="filled"
                    label={key.charAt(0).toUpperCase() + key.slice(1)}
                    defaultValue={paymentInfo[key]}
                    margin="normal"
                    fullWidth
                    sx={{ display: 'block' }}
                  />
                )
            )}
            <LoadingButton
              sx={{
                margin: '0 auto',
                display: 'flex',
                mt: '20px'
              }}
              onChange={fieldsValidation}
              variant="outlined"
              onClick={() => {
                sendPaymentData(payment.id, payment, fields)
              }}
              disabled={payment.fields.length !== 0 ? !validation : false}
            >
              {t('buttons.deposit')}
            </LoadingButton>
            {error ? <ErrorMessage error={error} /> : null}
          </TabPanel>
        ))}
        <PaymentDialog
          open={open}
          setOpen={setOpen}
          payData={payData}
          oneMethod={oneMethod}
          setLoading={setLoading}
        />
      </Box>
    )
  }

  const getTemplayteNewPayment = () => {
    return (
      <div className="payment__container">
        <div className="paymentPage">
          <h2 className="paymentPageTitle">Выберите удобный способ оплаты</h2>
          <div className="paymentPagePrice">
            <p>Сумма заказа</p>
            <p>{paymentInfo.amount}₽</p>
          </div>
          <div className="paymentPage__block">
            {paymentMethods.map((payment, i) => (
              <div
                key={payment.id}
                className="paymentPageItem"
                style={
                  payment.name === 'СБП'
                    ? { backgroundColor: '#F6F6F6', borderColor: '#269300' }
                    : {}
                }
                {...a11yProps(i)}
                onClick={() => clearError}
              >
                {getImgPayment(payment.name)}
                <Button
                  sx={{
                    background: '#3E3E3E',
                    width: '100%',
                    height: '50px',
                    fontFamily: 'inherit'
                  }}
                  className="paymentPage__btn"
                  variant="contained"
                  onClick={() => {
                    sendPaymentData(payment.id, payment, {})
                  }}
                >
                  {t('buttons.deposit')}
                </Button>
              </div>
            ))}
          </div>
          <PaymentDialog
            open={open}
            setOpen={setOpen}
            payData={payData}
            oneMethod={oneMethod}
            setLoading={setLoading}
          />
        </div>
        {loading && !error ? <Loading loading={loading} /> : null}
      </div>
    )
  }

  const getTemplate = () => {
    switch (true) {
      case window.location.hostname.includes('juun'):
        return getTemplayteNewPayment()
      case (window.location.hostname.includes('xn--80a5afkq.xn--80asehdb') || window.location.hostname.includes('штраф.онлайн')) :
        return getTemplateOldPayment()
      case (window.location.hostname.includes('bet2u')) :
        return getTemplateOldPayment()
      default:
        return getTemplayteNewPayment()
    }
  }
  return (
    <>
      {getTemplate()}
    </>
  )
}

export { Payment }
